body {
  margin: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  color:#546e7a;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #f4f5f7;
  --color: #6C5DD3;
}

button:focus{
  outline: none !important;
}
.leaflet-container {
  height: 300px;
  width: 100%;
}